body,
html {
    height: 100%;
}

body {
    font-family: -apple-system, "Helvetica Neue", "Lucida Grande";
}

.text-responsive {
    font-size: calc(100% + 1vw + 1vh);
}

.footer-nav a {
    display: inline-block;
    padding: 0 20px;
}

.card {
    border-radius: 4px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
    transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
        0.3s box-shadow,
        0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
    padding: 18px 36px 18px 36px;
    cursor: pointer;
    margin-bottom: 20px;
}

@media (hover: hover) {
    .card:hover {
        transform: scale(1.04);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12),
            0 4px 8px rgba(0, 0, 0, 0.06);
    }
}

.card h4 {
    font-weight: 500;
}

@media (max-width: 990px) {
    .card {
        margin: 20px;
        padding: 12px 24px 12px 24px;
    }
}

.badge-custom span {
    margin-left: 4px;
}

ul.pagination > .pagination-previous,
ul.pagination > .pagination-next {
    display: none;
}

.page-link:focus {
    box-shadow: none !important;
    border-color: transparent !important;
}

.pagination > .page-item:nth-of-type(2) > a {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.pagination > .page-item:nth-last-of-type(2) > a {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.pagination > .active > a {
    border: solid 1px #ebeff2 !important;
}

.pagination > .active > a:hover {
    cursor: default;
}

/* Colors (dark mode support) */
:root {
    --color-light-pagination-active: #ebeff2;
    --color-light-pagination-active-hover: #ccc;
    --color-light-pagination-hover: #ddd;
    --color-light-pagination: white;

    --color-dark-pagination-active: #434343;
    --color-dark-pagination-active-hover: rgb(106, 106, 106);
    --color-dark-pagination-hover: #5a5a5a;
    --color-dark-pagination: rgb(106, 106, 106);
}

.pagination > li > a {
    background-color: var(--color-light-pagination);
    border-color: var(--color-light-pagination-active);
}

.pagination > .active > a:hover {
    background-color: var(--color-light-pagination-active-hover) !important;
    border: solid 1px var(--color-light-pagination-active-hover);
}

.pagination > .active > a {
    background-color: var(--color-light-pagination-active) !important;
    border: solid 1px var(--color-light-pagination-active) !important;
}

.pagination > li > a:hover {
    background-color: var(--color-light-pagination-hover);
    border-color: var(--color-light-pagination-active);
}

.row > .badge-custom {
    padding: 4px 8px 4px 8px;
    margin: 4px;
    background-color: var(--color-light-pagination-active);
}

.dark-mode .row > .badge-custom {
    background-color: var(--color-dark-pagination-active);
}

.dark-mode .pagination > li > a {
    background-color: var(--color-dark-pagination);
    border-color: var(--color-dark-pagination-active);
}

.dark-mode .pagination > li > a:hover {
    background-color: var(--color-dark-pagination-hover);
    border-color: var(--color-dark-pagination-active);
}

.dark-mode .pagination > .active > a {
    background-color: var(--color-dark-pagination-active) !important;
    border: solid 1px var(--color-dark-pagination-active) !important;
}

#typed {
    font-size: min(5.2cqw, 24px);
}
